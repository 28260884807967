.help {
  padding: 2rem;

  &__title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  &__sub-title {
    font-size: 1.6rem;
    font-weight: lighter;
    margin-bottom: 1rem;
  }
  &__info {
    font-size: 1.4rem;
    margin-bottom: 1rem;
    li {
      margin-bottom: 1rem;
    }
  }
}
