header {
  .navigation {
    height: 5rem;
    width: 90%;
    margin: 0 auto;
    border-bottom: 1px solid #444;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-size: 2rem;
      .heart svg {
        animation: popUp 3s ease-in 0s infinite forwards;
      }

      @keyframes popUp {
        0% {
          transform: rotate(20deg) scale(1) translate(-0.3rem, 1rem);
        }

        50% {
          transform: rotate(20deg) scale(1.2) translate(-0.3rem, 1rem);
        }

        100% {
          transform: rotate(20deg) scale(1) translate((-0.3rem, 1rem));
        }
      }
    }

    &__menu-icon {
      font-size: 4rem;
    }

    &__menu {
      position: absolute;
      border: 2px solid black;
      background-color: $menu-bg-color;
      height: 100vh;
      width: 100%;
      color: $menu-color;
      top: 0;
      right: 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 10;
      display: none;

      li {
        font-size: 2rem;
        margin: 1rem;
      }
    }

    .show {
      display: flex;
    }
  }
}
