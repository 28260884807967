.home {
  display: block;
  width: 80%;
  margin: 0 auto;
  padding: 2rem;

  &-container {
    width: 90%;
    justify-content: center;
  }

  &__sub-title {
    font-size: 1.5rem;
    font-weight: lighter;
    // padding-left: 1rem;
    text-align: center;
    margin-bottom: 1rem;
    .quote {
      font-size: 2rem;
      font-weight: normal;
    }
  }

  ul {
    li {
      font-size: 1.2rem;
      margin-bottom: 1rem;

      &.question {
        font-size: 1rem;
        font-weight: lighter;
      }
    }
  }
}
