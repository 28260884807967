footer {
  position: absolute;
  bottom: 0;
  height: 10rem;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  li {
    font-size: 1rem;
  }
}
