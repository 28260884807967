.inspiration {
  &__title {
    text-align: center;
    margin-top: 1rem;
  }

  &__info {
    padding: 2rem;
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
}
