* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  z-index: 1;
}

html {
  min-height: 100%;
  position: relative;
}

body {
  font-size: 0.625%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: $bg-color;
  line-height: 1.5;
  color: $color;
}

#root {
  min-height: 100vh;
  padding-bottom: 10rem;
}

ul {
  list-style: none;
}

h1,
h2,
h3 {
  font-size: 2rem;
}

p,
li,
a,
a:visited,
a:link {
  font-size: 1.2rem;
  color: inherit;
  text-decoration: none;
}
